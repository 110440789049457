import React from 'react';
import { kebabCase } from 'lodash';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { Link, graphql } from 'gatsby';
import Layout from '../../containers/Layout';
import ImageResponsiveSquare from '../../components/ImageResponsiveSquare';
import Grid from '../../components/Grid';
import Title from '../../components/Title';
import Section from '../../components/Section';
import Label from '../../components/Label';
import Button from '../../components/Button';
import Hero from '../../containers/Hero';

const TagBox = styled.div`
  margin-bottom: 50px;
`;

const ButtonWithMargin = styled(Button.withComponent(Link))`
  margin: 0 3rem 1rem 0;

  &:last-of-type {
    margin-right: 0;
  }
`;

const WorkPage = ({
  pageContext: { tag },
  data: {
    tags: { group: allTags },
    projects: { edges: posts },
  },
}) => (
  <Layout>
    <Helmet title={tag || 'Selected projects'} />
    <Hero secondary>
      <Label>Projects</Label>
      <Title>Selected projects</Title>
    </Hero>
    <Section first>
      <TagBox>
        <ButtonWithMargin active={!tag} to={`/projects`}>
          All projects
        </ButtonWithMargin>
        {allTags.map(({ fieldValue: tagName }) => (
          <ButtonWithMargin
            key={tagName}
            active={tag === tagName}
            to={`/projects/${kebabCase(tagName)}/`}
          >
            {tagName}
          </ButtonWithMargin>
        ))}
      </TagBox>
      <Grid cols={2}>
        {posts.map(({ node: post }, i) => (
          <Link key={post.frontmatter.title} to={post.fields.slug}>
            <ImageResponsiveSquare
              imgSrc={post.frontmatter.thumbnail}
              title={post.frontmatter.title}
              subtitle={post.frontmatter.tags && post.frontmatter.tags.join(', ')}
            />
          </Link>
        ))}
      </Grid>
    </Section>
  </Layout>
);

export default WorkPage;

export const workPageQuery = graphql`
  query WorkPageQuery {
    tags: allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
    projects: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___title] }
      filter: { frontmatter: { templateKey: { eq: "case-study" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            thumbnail
            tags
          }
        }
      }
    }
  }
`;
