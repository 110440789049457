import styled from 'styled-components';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.cols}, 1fr);
  grid-gap: 60px 60px;

  ${({ theme }) => theme.media.tablet`
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px 40px;
  `} ${({ theme }) => theme.media.mobile`
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px 20px;
  `};
`;

export default Grid;
