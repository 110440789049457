import React, { Component } from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  width: 100%;

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  &:hover {
    img {
      transform: scale(1.1);
    }
  }
`;

const Content = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const ImageScale = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: 50%;

  img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    transition: transform ${({ theme }) => theme.effects.transition.base};
  }
`;

const Caption = styled.div`
  position: relative;
  z-index: 10;
  padding: 2rem;
`;

const loadingStyle = css`
  opacity: 0;
  transition: opacity ${({ theme }) => theme.effects.transition.slow} 0.4s;

  ${({ isLoaded }) =>
    isLoaded &&
    css`
      opacity: 1;
    `};
`;

const ProjectTitle = styled.h3`
  margin: 0;
  line-height: ${({ theme }) => theme.font.lineHeight.caption};
  ${loadingStyle}

  ${({ theme, isTextLight}) =>
    isTextLight &&
    css`
      color: ${theme.color.text.tertiary};
    `};
`;

const ProjectSubtitle = styled.p`
  margin: 0.5rem 0 0;
  ${loadingStyle}

  ${({ theme, isTextLight }) =>
    isTextLight &&
    css`
      color: ${theme.color.text.secondary};
    `};
`;

class ImageResponsiveSquare extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isImageLoaded: false,
      isTextLight: false,
    };

    this.threshold = 0.3;
  }

  getImageLightness(imageSrc) {
    const img = document.createElement('img');
    img.src = imageSrc;
    img.style.display = 'none';
    const mountedImage = document.body.appendChild(img);

    let colorSum = 0;

    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);

      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;
      let r, g, b, avg;

      for (let x = 0, len = data.length; x < len; x += 4) {
        r = data[x];
        g = data[x + 1];
        b = data[x + 2];

        avg = Math.floor((r + g + b) / 3);
        colorSum += avg;
      }

      const brightness = Math.floor(colorSum / (img.width * img.height));

      this.setState(
        {
          isImageLoaded: true,
          isTextLight: brightness / 255 < this.threshold,
        },
        () => {
          mountedImage.remove();
        }
      );
    };
  }

  componentDidMount() {
    this.getImageLightness(this.props.imgSrc);
  }

  render() {
    const { imgSrc, title, subtitle } = this.props;
    const { isImageLoaded, isTextLight } = this.state;

    return (
      <Wrapper>
        <Content>
          <ImageScale>
            <img src={imgSrc} alt={title} />
          </ImageScale>
          <Caption>
            <ProjectTitle isLoaded={isImageLoaded} isTextLight={isTextLight}>
              {title}
            </ProjectTitle>
            <ProjectSubtitle isLoaded={isImageLoaded} isTextLight={isTextLight}>
              {subtitle}
            </ProjectSubtitle>
          </Caption>
        </Content>
      </Wrapper>
    );
  }
}

export default ImageResponsiveSquare;
